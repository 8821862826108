<template>
	<v-dialog v-model="dialogRegister" persistent max-width="500px">
		<v-row align="center" justify="center" no-gutters>
			<v-col cols="12">
				<v-card class="elevation-20">
					<v-toolbar color="primary" dark flat>
						<v-toolbar-title>{{ $t('registerTitle') }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn icon dark @click="close">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar>
					<v-card-text>
						<v-form ref="form">
							<v-text-field
								:label="$t('email')"
								name="email"
								prepend-icon="mdi-account"
								type="email"
								v-model="email"
								:rules="emailRules"
								required
							></v-text-field>

							<v-text-field
								id="password"
								:label="$t('password')"
								name="password"
								prepend-icon="mdi-lock"
								type="password"
								v-model="password"
								:rules="passwordRules"
								required
							></v-text-field>

							<v-text-field
								id="confirm-password"
								:label="$t('confirmPassword')"
								name="confirmPassword"
								prepend-icon="mdi-lock"
								type="password"
								v-model="confirmPassword"
								:rules="confirmPasswordRules"
								required
							></v-text-field>

							<v-checkbox
								class=""
								v-model="termsAndGdpr"
								:rules="termsAndGdprRules"
								required
							>
								<template v-slot:label>
									<div>
										{{ $t('termsAndGdpr') }}
										<router-link
											@click.native="close"
											to="/termsandconditionsbg"
										>
											{{ $t('termsAndConditions') }}
										</router-link>
										{{ $t('and') }}
										<router-link @click.native="close" to="/privacypolicybg">
											{{ $t('gdprPolicy') }}
										</router-link>
									</div>
								</template>
							</v-checkbox>

							<!-- Login error -->
							<v-alert v-model="confirmPassErr" type="error" dense dismissible>
								{{ $t('confirmPasswordError') }}
							</v-alert>
							<v-alert v-model="generalErr" type="error" dense dismissible>
								{{ $t('generalError') }}
							</v-alert>
							<v-alert v-model="duplicateErr" type="error" dense dismissible>
								{{ $t('userAlredyExists') }}
							</v-alert>
							<v-alert v-model="badPassword" type="error" dense dismissible>
								{{ $t('badPassword') }}
							</v-alert>
						</v-form>

						<!-- Loader -->
						<div v-if="isLoading" class="text-center">
							<v-progress-circular
								indeterminate
								color="primary"
							></v-progress-circular>
						</div>
					</v-card-text>

					<v-card-actions class="pa-5">
						<v-btn color="primary" block @click="onSubmit">{{
							$t('register')
						}}</v-btn>
					</v-card-actions>

					<v-card-actions>
						<div class=" ml-4 mb-4">
							{{ $t('alreadyRegistered') }}
							<a @click.prevent="$emit('already-registered')">{{
								$t('login')
							}}</a>
						</div>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
export default {
	name: 'RegisterForm',

	props: {
		dialogRegister: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			email: '',
			password: '',
			confirmPassword: '',
			termsAndGdpr: false,
			confirmPassErr: false,
			badPassword: false,
			duplicateErr: false,
			generalErr: false,
			isLoading: false,
			emailRules: [
				v => !!v || this.$i18n.t('requiredField'),
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
			passwordRules: [v => !!v || this.$i18n.t('requiredField')],
			confirmPasswordRules: [v => !!v || this.$i18n.t('requiredField')],
			termsAndGdprRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	methods: {
		close() {
			this.$emit('close-register');
		},
		async onSubmit() {
			this.confirmPassErr = false;
			this.badPassword = false;
			this.duplicateErr = false;
			this.generalErr = false;

			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			if (this.password !== this.confirmPassword) {
				this.confirmPassErr = true;
				return;
			}

			this.isLoading = true;

			try {
				const res = await this.$http.post(`${this.url}/auth/register`, {
					email: this.email,
					password: this.password,
					acceptTerms: this.termsAndGdpr,
					acceptGdpr: this.termsAndGdpr,
				});

				this.isLoading = false;

				this.$cookies.set('token', res.data.token);
				this.close();
				this.$router.push(`/registered/${res.data.data._id}`);
			} catch (error) {
				console.error(error);
				this.isLoading = false;

				if (error.response.data.error.includes('ValidationError')) {
					this.badPassword = true;
				} else if (error.response.data.error.includes('MongoError: E1100')) {
					this.duplicateErr = true;
				} else {
					this.generalErr = true;
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
